import React, { Component } from "react";
import { Button, Tooltip, Space, Input } from "antd";
import Header from "../../components/header";
import Footer from "../../components/footer";

import first from "../../assets/images/production/first.png";
import second from "../../assets/images/production/second.png";
import third from "../../assets/images/production/third.png";
import title2 from "../../assets/images/production/title2.png";

import "../../assets/css/production.css";

export class Production extends Component {
  constructor(props) {
    super(props);
    //   this.state = { alertStatus: false };
    this.Header = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  sqsy = () => {
    //调用父组件中的函数
    this.Header.current.sqsy();
  };

  render() {
    return (
      <>
        <Header ref={this.Header}></Header>

        <div className="production">
          <div className="productioncon">
            <div className="left">
              <div className="titleimg">
                <img src={title2} width="300" height="40" alt="" />
              </div>
              <div className="centercon">
                网点投入/增加客户粘性/增加货物涞源
              </div>
              <div
                className="btn"
                onClick={() => {
                  this.sqsy();
                }}
              >
                <Button type="primary">申请试用</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="introduce">
          <div className="introducecon">
            <div className="first">
              <div className="introduceimg">
                <img src={first} width="600" height="300" alt="" />
              </div>
              <div className="introducetext">
                <div className="text">一部手机</div>
                <div className="text">一台蓝牙打印机</div>
                <div className="text">就能完成全部业务，减少网点投入成本</div>
              </div>
            </div>
            <div className="first">
              <div className="introducetext">
                <div className="text">微信、小程序、短信通知</div>
                <div className="text">多渠道通知客户</div>
                <div className="text">增加客户粘性</div>
              </div>
              <div className="introduceimg">
                <img src={second} width="600" height="300" alt="" />
              </div>
            </div>
            <div className="first">
              <div className="introduceimg">
                <img src={third} width="600" height="300" alt="" />
              </div>
              <div className="introducetext">
                <div className="text">增加各专线之间的联系</div>
                <div className="text">平台合作网点间互助收获</div>
                <div className="text">自动清分</div>
                <div className="text">增加货物涞源</div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </>
    );
  }
}
export default Production;
