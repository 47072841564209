import React, { Component } from "react";
import { Button, Tooltip, Space, Input } from "antd";
import Header from "../../components/header";
import Footer from "../../components/footer";

import first from "../../assets/images/production/inner1.png";
import second from "../../assets/images/production/inner2.png";
import title1 from "../../assets/images/production/title1.png";

import "../../assets/css/innergl.css";

class Innergl extends Component {
  constructor(props) {
    super(props);
    //   this.state = { alertStatus: false };
    this.Header = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  sqsy = () => {
    //调用父组件中的函数
    this.Header.current.sqsy();
  };

  render() {
    return (
      <>
        <Header ref={this.Header}></Header>

        <div className="production3">
          <div className="productioncon">
            <div className="left">
              <div className="titleimg">
                <img src={title1} width="300" height="40" alt="" />
              </div>
              <div className="centercon">为运力找出口/业务交接更简单</div>
              <div
                className="btn"
                onClick={() => {
                  this.sqsy();
                }}
              >
                <Button type="primary">申请试用</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="introduce3">
          <div className="introducecon">
            <div className="first">
              <div className="introduceimg">
                <img src={first} width="600" height="300" alt="" />
              </div>
              <div className="introducetext">
                <div className="text">
                  专线、物流车、商户、厂家、快递、快运均可使用的业务互助平台，为货源找运力，为运力找出口
                </div>
              </div>
            </div>
            <div className="first">
              <div className="introducetext">
                <div className="text">业务交接简单易操作，结算利润报表清晰</div>
              </div>
              <div className="introduceimg">
                <img src={second} width="600" height="300" alt="" />
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </>
    );
  }
}
export default Innergl;
