import React, { Component } from "react";
import * as echarts from "echarts";
import Header from "../../components/header";
import Footer from "../../components/footer";
//article
import article from "../../assets/images/home/article.png";

import "../../assets/css/logistics.css";

export class Logistics extends Component {

    

    componentDidMount() {
      
        window.scrollTo(0, 0);

    // 初始化echarts实例，将其挂载到id为main的dom元素上展示
    var myChart = echarts.init(document.getElementById("main"));
    // 绘制图表
    myChart.setOption({
      title: {
        text: "前十城市进出港货物量统计",
        // subtext: "Fake Data",
      },
      tooltip: {
        trigger: "axis",
      },
      // legend: {
      //   data: ["Rainfall", "Evaporation"],
      // },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      calculable: true,
      xAxis: [
        {
          type: "category",
          // prettier-ignore
          data: ['上海', '广州', '北京', '深圳', '天津', '杭州', '济南', '重庆', '成都', '西安'],
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "出货量",
          type: "bar",
          data: [
            // 2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
            0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" },
            ],
          },
          // markLine: {
          //   data: [{ type: "average", name: "Avg" }],
          // },
        },
      ],
    });

    // 初始化echarts实例，将其挂载到id为main的dom元素上展示
    var myChart2 = echarts.init(document.getElementById("main2"));
    // 绘制图表
    myChart2.setOption({
      title: {
        text: "前十城市进出港货物量时间趋势",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      // legend: {
      //   data: ["Email", "Union Ads"],
      // },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "货物量",
          type: "line",
          stack: "Total",
          smooth: true,
          label: {
            show: true,
            position: "top",
          },
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          //   data: [12, 28, 38, 10, 47, 28, 22, 32, 30, 21, 40, 42],
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
    });

    // 初始化echarts实例，将其挂载到id为main的dom元素上展示
    var myChart3 = echarts.init(document.getElementById("main3"));
    // 绘制图表
    myChart3.setOption({
      ttitle: {
        text: "全品类进出港货量分析",
        subtext: "Fake Data",
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["进港", "出港"],
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      calculable: true,
      xAxis: [
        {
          type: "category",
          // prettier-ignore
          data: ['蔬菜', '水果', '肉蛋', '熟食', '粮油', '速食', '调味', '冻品', '零食', '乳品', '水饮', '酒类'],
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "进港",
          type: "bar",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" },
            ],
          },
          // markLine: {
          //   data: [{ type: "average", name: "Avg" }],
          // },
        },
        {
          name: "出港",
          type: "bar",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          markPoint: {
            data: [
              { name: "Max", value: 182.2, xAxis: 7, yAxis: 183 },
              { name: "Min", value: 2.3, xAxis: 11, yAxis: 3 },
            ],
          },
          // markLine: {
          //   data: [{ type: "average", name: "Avg" }],
          // },
        },
      ],
    });
  }

  render() {
    return (
      <>
        <Header></Header>

        <div className="logistics">
          <div className="echars" id="main"></div>
          <div className="echars" id="main2"></div>
          <div className="echars" id="main3"></div>
          <div className="article">
            <div className="title">
              <div>相关文章</div>
              {/* <div className="more">更多&gt;&gt;</div> */}
            </div>
            <div className="articlecon">
              <div className="leftimg">
                <img src={article} width="220" height="210" alt="" />
              </div>
              <div className="rightitems">
                <div className="border"></div>
                <div className="items">
                  <div className="arttitle">
                    日均货量突破5000吨，这家企业是怎么做到的？
                  </div>
                  <div className="time">2023-05-07</div>
                </div>
                <div className="items">
                  <div className="arttitle">物流专业只能下苦力吗？</div>
                  <div className="time">2023-05-06</div>
                </div>
                <div className="items">
                  <div className="arttitle">你为什么会选择从事物流行业？</div>
                  <div className="time">2023-05-06</div>
                </div>
                <div className="items">
                  <div className="arttitle">有司机，找货源</div>
                  <div className="time">2023-02-10</div>
                </div>

                <div className="items">
                  <div className="arttitle">物流小知识（不定期更新）</div>
                  <div className="time">2023-02-10</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </>
    );
  }
}
export default Logistics;
