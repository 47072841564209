import  {
    Home,
    Admin,
    // Production

} from "../pages";   // 引入页面

export const homeRoute = [
    {
        pathname:"/home",
        component: Home
    }
];

// export const productionRoute = [
//     {
//         pathname:"/production",
//         component: Production
//     }
// ];

export const admainRoute = [
    {
        pathname: "/admin/home",
        component: Admin,
        exact: true
    }
];
