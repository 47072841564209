import React, { Component } from "react";
import {
  Button,
  Tooltip,
  Space,
  Input,
  Radio,
  DatePicker,
  TimePicker,
  Upload,
  message,
  Select,
  Modal,
  Image,
} from "antd";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import locale from "antd/es/date-picker/locale/zh_CN";
import "dayjs/locale/zh-cn";
import axios from "axios";
import PDF from "react-pdf-js";
import Header from "../../components/header";
import Footer from "../../components/footer";

import bottom from "../../assets/images/home/bottom.png";

// import '../../assets/css/zzfw.css'
import "../../assets/css/insure.css";

class Radiobtn extends Component {
  constructor(props) {
    super(props);
    this.state = { bdlx: 1, inputValue: "" };
  }

  onChange = (e) => {
    console.log("radio checked", e.target.value);
    const { sendFn } = this.props;
    sendFn(e.target.value);

    this.setState({
      bdlx: e.target.value,
    });
  };
  render() {
    return (
      <Radio.Group onChange={this.onChange} value={this.state.bdlx}>
        <Radio value={1}>平安险</Radio>
      </Radio.Group>
    );
  }
}

class Radiobtn2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cpxz: 0,
    };
  }

  onChange = (e) => {
    const { cpxzhandleUpdate, showhidden2 } = this.props;
    console.log("radio checked", e.target.value);
    this.setState({
      cpxz: e.target.value,
    });
    cpxzhandleUpdate(e.target.value);
    showhidden2();
  };
  render() {
    const { sendValue } = this.props;
    return (
      <Radio.Group onChange={this.onChange} value={this.state.cpxz}>
        <Radio value={351}>平安•普货险</Radio>
        <Radio value={350}>平安•大件险</Radio>
        <Radio value={352}>平安•综合险</Radio>
      </Radio.Group>
    );
  }
}

class Date extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateString: "",
    };
  }

  onDateChange = (date, dateString) => {
    console.log(date, dateString);
    this.setState({
      dateString: dateString,
    });
  };

  render() {
    return (
      <>
        <DatePicker
          locale={locale}
          style={{ width: "62%" }}
          onChange={this.onDateChange}
        />
      </>
    );
  }
}

//上传视频
const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
const Uploadradio = () => (
  <Upload {...props}>
    <Button icon={<UploadOutlined />}>上传视频</Button>
  </Upload>
);

const zjlxhandleChange = (value) => {
  console.log(`selected ${value}`);

  if (value.indexOf("投保") != -1) {
    console.log(11);
  }
};

//投保证件类型
class Selectchoise extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    this.props.bindTodo(value);
  }

  render() {
    return (
      <Select
        defaultValue="个人"
        style={{ width: "100%" }}
        onChange={this.handleChange}
        options={[
          { value: "个人", label: "个人" },
          { value: "公司", label: "公司" },
        ]}
      />
    );
  }
}
//投保证件类型
const Selectchoisetblx = () => (
  <Select
    defaultValue="身份证"
    style={{ width: "100%" }}
    onChange={zjlxhandleChange}
    options={[
      { value: "投保身份证", label: "身份证" },
      { value: "投保其他", label: "其他" },
    ]}
  />
);
//被保证件类型
const Selectchoisebblx = () => (
  <Select
    defaultValue="身份证"
    style={{ width: "100%" }}
    onChange={zjlxhandleChange}
    options={[
      { value: "被保身份证", label: "身份证" },
      { value: "被保其他", label: "其他" },
    ]}
  />
);

//被保证件类型
class Selectbbchoise extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    this.props.bbbindTodo(value);
  }

  render() {
    return (
      <Select
        defaultValue="个人"
        style={{ width: "100%" }}
        onChange={this.handleChange}
        options={[
          { value: "个人", label: "个人" },
          { value: "公司", label: "公司" },
        ]}
      />
    );
  }
}

class Citychose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      city: [],
      citycode: "",
      citylable: "",
    };
  }
  cityChange = (value) => {
    console.log(`selected ${value}`);
    this.props.citycode(value);
    for (let index = 0; index < this.state.city.length; index++) {
      if (this.state.city[index].value == value) {
        console.log(this.state.city[index]);
        this.setState({
          citylable: this.state.city[index].label,
          citycode: this.state.city[index].code,
        });
      }
    }
  };
  componentDidMount() {
    //获取省市区接口
    axios
      .post(`https://www.udao56.cn/public/index.php/api/Commonapi/getBxdq`, {
        code: "",
      })
      .then(
        (res) => {
          let city = [
            {
              code: 0,
              value: "",
              label: "请选择",
              id: 0,
            },
          ];
          for (let i = 0; i < res.data.data.length; i++) {
            let obj = {
              code: res.data.data[i].code,
              value: res.data.data[i].code,
              label: res.data.data[i].pro,
              id: res.data.data[i].id,
            };
            city.push(obj);
          }
          this.setState({
            city: city,
          });
        },
        (error) => {
          this.info("接口调用失败，请重新尝试");
        }
      );
  }
  render() {
    return (
      <Select
        defaultValue=""
        style={{
          width: "50%",
        }}
        onChange={this.cityChange}
        options={this.state.city}
      />
    );
  }
}

//市区选择
class Areachose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      area: [],
      areacode: "",
      arealable: "",
    };
  }
  componentDidMount() {
    this.props.onRef1(this);
  }
  areaChange = (value) => {
    console.log(`selected ${value}`);
    this.setState({
      areacode: value,
    });
    for (let index = 0; index < this.state.area.length; index++) {
      if (this.state.area[index].value == value) {
        console.log(this.state.area[index]);
        this.setState({
          arealable: this.state.area[index].label,
        });
      }
    }
  };
  myNames = () => {
    console.log(55887444);
    console.log(this.props.area);
    this.setState({
      area: this.props.area,
    });
    for (let index = 0; index < this.state.area.length; index++) {
      if (this.state.area[index].code == 0) {
        this.setState({
          areacode: "",
        });
      }
    }
  };
  render() {
    return (
      <Select
        value={this.state.areacode}
        style={{
          width: "50%",
        }}
        onChange={this.areaChange}
        options={this.props.area}
      />
    );
  }
}

class Citychosedd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      citydd: [],
      cityddlable: "",
      cityddcode: "",
    };
  }
  cityChangedd = (value) => {
    console.log(`selected ${value}`);
    this.props.citycodedd(value);
    for (let index = 0; index < this.state.citydd.length; index++) {
      if (this.state.citydd[index].value == value) {
        console.log(this.state.citydd[index]);
        this.setState({
          cityddlable: this.state.citydd[index].label,
          cityddcode: this.state.citydd[index].code,
        });
      }
    }
  };
  componentDidMount() {
    //获取省市区接口
    axios
      .post(`https://www.udao56.cn/public/index.php/api/Commonapi/getBxdq`, {
        code: "",
      })
      .then(
        (res) => {
          let citydd = [
            {
              code: 0,
              value: "",
              label: "请选择",
              id: 0,
            },
          ];
          for (let i = 0; i < res.data.data.length; i++) {
            let obj = {
              code: res.data.data[i].code,
              value: res.data.data[i].code,
              label: res.data.data[i].pro,
              id: res.data.data[i].id,
            };
            citydd.push(obj);
          }
          this.setState({
            citydd: citydd,
          });
        },
        (error) => {
          this.info("接口调用失败，请重新尝试");
        }
      );
  }
  render() {
    return (
      <Select
        defaultValue=""
        style={{
          width: "50%",
        }}
        onChange={this.cityChangedd}
        options={this.state.citydd}
      />
    );
  }
}

//市区选择
class Areachosedd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areadd: [],
      areacodedd: "",
      areaddlable: "",
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }
  areaChangedd = (value) => {
    console.log(`selected ${value}`);
    this.setState({
      areacodedd: value,
    });
    for (let index = 0; index < this.state.areadd.length; index++) {
      if (this.state.areadd[index].value == value) {
        console.log(this.state.areadd[index]);
        this.setState({
          areaddlable: this.state.areadd[index].label,
        });
      }
    }
  };
  myName = () => {
    console.log(55887444);
    console.log(this.props.areadd);
    this.setState({
      areadd: this.props.areadd,
    });
    for (let index = 0; index < this.state.areadd.length; index++) {
      if (this.state.areadd[index].code == 0) {
        this.setState({
          areacodedd: "",
        });
      }
    }
  };

  render() {
    return (
      <Select
        value={this.state.areacodedd}
        style={{
          width: "50%",
        }}
        onChange={this.areaChangedd}
        options={this.state.areadd}
      />
    );
  }
}

class Bzchose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packagingMode: "G0",
    };
  }
  bzChange = (value) => {
    console.log(`selected ${value}`);
    this.setState({
      packagingMode: value,
    });
  };

  render() {
    return (
      <Select
        defaultValue="G0"
        style={{
          width: "100%",
        }}
        onChange={this.bzChange}
        options={[
          {
            value: "G0",
            label: "散装(Bulk)",
          },
          {
            value: "H0",
            label: "托盘(Pallet)",
          },
          {
            value: "a0",
            label: "包、捆(Bale)B/S",
          },
          {
            value: "b0",
            label: "袋(Bag)Bgs",
          },
          {
            value: "b1",
            label: "麻袋(Gunny Bag)Bgs",
          },
          {
            value: "b2",
            label: "纸袋(Paper Bag)Bgs",
          },
          {
            value: "b3",
            label: "布袋(Sack) Sks",
          },
          {
            value: "b4",
            label: "人造革袋(Leatheroid Bag) Bgs",
          },
          {
            value: "c0",
            label: "各种木箱(Case)C/S",
          },
          {
            value: "c1",
            label: "纸箱(Carton)Ctns",
          },
          {
            value: "c2",
            label: "胶板箱(Plywood)C/S,/CS",
          },
          {
            value: "c3",
            label: "板条、亮格箱(Crate)Crts",
          },
          {
            value: "d0",
            label: "各种金属桶(Iron Drums) Drms, D/S",
          },
          {
            value: "d1",
            label: "塑料桶(Plastic Drums)",
          },
          {
            value: "d2",
            label: "鼓形木桶(Barrel) Brls",
          },
          {
            value: "d3",
            label: "大木桶(Hogshead) Hghds",
          },
          {
            value: "d4",
            label: "小木桶(Keg) Kgs",
          },
          {
            value: "d5",
            label: "纸板桶(Fibre Drum)",
          },
          {
            value: "e0",
            label: "捆扎(Bundle) Bdle",
          },
          {
            value: "e1",
            label: "卷筒等(Roll,Reel,Coil)",
          },
          {
            value: "e2",
            label: "篓筐(Basket) Bkts",
          },
          {
            value: "e3",
            label: "坛、甏(Jar)",
          },
          {
            value: "e4",
            label: "瓶(Bottle)",
          },
          {
            value: "e5",
            label: "钢瓶(Cylinder)",
          },
          {
            value: "e6",
            label: "罐(Can)",
          },
          {
            value: "f1",
            label: "块(Pig)",
          },
          {
            value: "f2",
            label: "管(Pipe)",
          },
          {
            value: "f3",
            label: "条、棒(Bar)",
          },
          {
            value: "f4",
            label: "张(Sheet) Shts",
          },
          {
            value: "f5",
            label: "个、件(Piece) Pcs",
          },
          {
            value: "f6",
            label: "头、匹(Head) Hds",
          },
          {
            value: "f7",
            label: "裸装(Unpacked)",
          },
          {
            value: "",
            label: "散装及托盘包装",
          },
        ]}
      />
    );
  }
}

export class Insure2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isshow: "none",
      transform: "rotate(0deg)",
      isshowcp: "block",
      isshowbxinfo: "block",
      isshowysinfo: "block",
      isshowhwinfo: "block",
      isshowtblx: "block", //投保类型，个人时显示投保人证件号，公司时不显示
      isshowbblx: "block", //被保类型，个人时显示投保人证件号，公司时不显示
      inputValue: "",
      cpxzlx: ["平安•普货险", "平安•大件险", "平安•综合险"],
      cpxz: 0,
      isshowbx: "block",
      city: [],
      citycode: "",
      area: [],
      areacode: "",
      citydd: [],
      citycodedd: "",
      areadd: [],
      areacodedd: "",
      form: {
        tbphone: "",
        tbname: "",
        tbsfz: "",
        bbname: "",
        bbsfz: "",
      },
      open: false,
      opendzbd: false,
      policyimg: "",
      pagetotal: 0,
      page: 1,
      loading: false,
    };
    this.bindTodo = this.bindTodo.bind(this);
    this.bbbindTodo = this.bbbindTodo.bind(this);
  }
  bindTodo() {
    if (this.state.isshowtblx == "none") {
      this.setState({
        isshowtblx: "block",
      });
    }
    if (this.state.isshowtblx == "block") {
      this.setState({
        isshowtblx: "none",
      });
    }
  }
  bbbindTodo() {
    if (this.state.isshowbblx == "none") {
      this.setState({
        isshowbblx: "block",
      });
    }
    if (this.state.isshowbblx == "block") {
      this.setState({
        isshowbblx: "none",
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  showhidden() {
    console.log(this.state.isshow); //cpxz
    console.log(this.state.cpxz); //cpxz
    if (this.state.isshow == "none" && this.state.cpxz != 0) {
      this.setState({
        isshow: "block",
        transform: "rotate(180deg)",
      });
    }
    if (this.state.isshow == "block") {
      this.setState({
        isshow: "none",
        transform: "rotate(0deg)",
      });
    }
  }
  showhidden2 = () => {
    console.log(123);
    this.setState({
      isshow: "block",
      transform: "rotate(180deg)",
      isshowbx: "none", //判断保险类型的选择
    });
  };

  isshowcp() {
    if (this.state.isshowcp == "none") {
      this.setState({
        isshowcp: "block",
      });
    }
    if (this.state.isshowcp == "block") {
      this.setState({
        isshowcp: "none",
      });
    }
  }
  isshowbxinfo() {
    if (this.state.isshowbxinfo == "none") {
      this.setState({
        isshowbxinfo: "block",
      });
    }
    if (this.state.isshowbxinfo == "block") {
      this.setState({
        isshowbxinfo: "none",
      });
    }
  }
  isshowysinfo() {
    if (this.state.isshowysinfo == "none") {
      this.setState({
        isshowysinfo: "block",
      });
    }
    if (this.state.isshowysinfo == "block") {
      this.setState({
        isshowysinfo: "none",
      });
    }
  }
  isshowhwinfo() {
    if (this.state.isshowhwinfo == "none") {
      this.setState({
        isshowhwinfo: "block",
      });
    }
    if (this.state.isshowhwinfo == "block") {
      this.setState({
        isshowhwinfo: "none",
      });
    }
  }

  isshowtblx() {
    if (this.state.isshowtblx == "none") {
      this.setState({
        isshowtblx: "block",
      });
    }
    if (this.state.isshowtblx == "block") {
      this.setState({
        isshowtblx: "none",
      });
    }
  }

  cpxzhandleUpdate = (inputValue) => {
    console.log(inputValue);
    this.setState({
      cpxz: inputValue,
    });
  };

  handleUpdate = (inputValue) => {
    console.log(inputValue);

    if (inputValue == 1) {
      this.setState({
        cpxzlx: ["人保•商品车运输险", "人保•轿车运输险"],
      });
    }
    if (inputValue == 2) {
      this.setState({
        cpxzlx: ["人保•果蔬险", "人保•鲜活冷藏险", "平安•果蔬险"],
      });
    }
    if (inputValue == 3) {
      this.setState({
        cpxzlx: [
          "人保•普货运输险",
          "中路•综合险",
          "中路•基本险",
          "平安•综合险",
          "平安•基本险",
          "平南京人保•综合险",
          "南京人保•基本险",
        ],
      });
    }
    if (inputValue == 4) {
      this.setState({
        cpxzlx: ["人保•港澳台邮包险", "人保•国际邮包险", "人保•国内邮包险"],
      });
    }
    if (inputValue == 5) {
      this.setState({
        cpxzlx: ["人保•易碎运输险", "平安•易碎险", "山东平安•易碎品险"],
      });
    }
    if (inputValue == 6) {
      this.setState({
        cpxzlx: ["人保•大件运输险", "平安大件•风电运输险", "平安•大件险"],
      });
    }
    if (inputValue == 7) {
      this.setState({
        cpxzlx: ["中路•城际综合险", "中路•城际基本险", "平安•城际基本险"],
      });
    }
    if (inputValue == 8) {
      this.setState({
        cpxzlx: ["平安*危货品综合险", "平安*危货品基本险"],
      });
    }
    if (inputValue == 9) {
      this.setState({
        cpxzlx: [
          "国寿财•易碎险（新疆、西藏）",
          "国寿财•综合险（新疆、西藏）",
          "国寿财•易碎险(普通路线)",
          "国寿财•大件(新疆、西藏线路)",
          "国寿财•大件(普通路线)",
          "国寿财•综合险(普通路线)",
        ],
      });
    }
    console.log(this.state.cpxzlx);
  };

  //form提交
  formbtn = () => {
    this.setState({
      loading: true,
    });
    let sfaddress =
      this.refs.citycode.state.citylable + this.refs.areacode.state.arealable;
    // console.log(sfaddress);
    let ddaddress =
      this.refs.citycodedd.state.cityddlable +
      this.refs.areacodedd.state.areaddlable;
    // console.log(ddaddress);

    let tjobj = {};
    tjobj.product_id = this.state.cpxz; //产品 大件险(350) 基本险(351) 综合险(352)
    tjobj.beneficiary = this.state.form.beneficiary.input.value; //被保险人
    tjobj.belink = this.state.form.belink.input.value; //被保人联系人
    tjobj.beaddress = this.state.form.beaddress.input.value; //被保人联系地址
    tjobj.bephone = this.state.form.bephone.input.value; //被保人联系电话
    tjobj.licensePlate = this.state.form.licensePlate.input.value; //被保人联系电话
    tjobj.fromPortDesc = sfaddress; //始发地址起运地
    tjobj.bpro = this.refs.citycode.state.citycode; //省code
    tjobj.bcity = this.refs.areacode.state.areacode; //市code
    tjobj.toPortDesc = ddaddress; //目的地
    tjobj.epro = this.refs.citycodedd.state.cityddcode;
    tjobj.ecity = this.refs.areacodedd.state.areacodedd;
    tjobj.cargoFromDate = this.refs.datestring.state.dateString; //起运时间
    tjobj.cargoFromDateHr = this.state.form.cargoFromDateHr.input.value; //起运小时24小时
    tjobj.packagingMode = this.refs.bzchose.state.packagingMode; //包装
    tjobj.cargoDesc = this.state.form.cargoDesc.input.value; //货物的描述及数量
    tjobj.amountpa = this.state.form.amountpa.input.value; //保险金额
    console.log(tjobj);

    if (tjobj.product_id == 0) {
      // 请先选择货险类型
      message.error("失败，请先选择货险类型...");
      this.setState({
        loading: false,
      });
      return;
    }
    if (
      tjobj.beneficiary == "" ||
      tjobj.beaddress == "" ||
      tjobj.licensePlate == "" ||
      tjobj.bpro == "" ||
      tjobj.bcity == "" ||
      tjobj.epro == "" ||
      tjobj.ecity == "" ||
      tjobj.cargoFromDate == "" ||
      tjobj.cargoFromDateHr == "" ||
      tjobj.packagingMode == "" ||
      tjobj.cargoDesc == "" ||
      tjobj.amountpa == ""
    ) {
      // 请先选择货险类型
      message.error("失败，请认真填写必填项...");
      this.setState({
        loading: false,
      });
      return;
    }

    //请求提交接口
    // axios.defaults.headers.common["token"] = "c0d9ded8396f338d4e169d9e10e8bde2";
    axios
      .post(`http://cs.udao56.cn/public/index.php/api/Pingan/tbSend`, {
        product_id: tjobj.product_id, //产品 大件险(350) 基本险(351) 综合险(352)
        beneficiary: tjobj.beneficiary, //被保险人
        belink: tjobj.belink, //被保人联系人
        beaddress: tjobj.beaddress, //被保人联系地址
        bephone: tjobj.bephone, //被保人联系电话
        licensePlate: tjobj.licensePlate, //被保人联系电话
        fromPortDesc: tjobj.fromPortDesc, //始发地址起运地
        bpro: tjobj.bpro, //省code
        bcity: tjobj.bcity, //市code
        toPortDesc: tjobj.toPortDesc, //目的地
        epro: tjobj.epro,
        ecity: tjobj.ecity,
        cargoFromDate: tjobj.cargoFromDate, //起运时间
        cargoFromDateHr: tjobj.cargoFromDateHr, //起运小时24小时
        packagingMode: tjobj.packagingMode, //包装
        cargoDesc: tjobj.cargoDesc, //货物的描述及数量
        amountpa: tjobj.amountpa, //保险金额
      })
      .then(
        (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            message.error("失败，" + res.data.data.info);
            this.setState({
              loading: false,
              // open: true,
              // policyno: 12288003902042911325,
              // price: 0.1,
            });
          } else {
            message.success("成功，投保成功");
            this.setState({
              open: true,
              policyno: res.data.data.policyno,
              price: res.data.data.price,
              loading: false,
            });
          }
        },
        (error) => {
          console.log(error);
          this.info("接口调用失败，请重新尝试");
          this.setState({
            loading: false,
          });
          // console.log('接口有问题');
        }
      );
  };

  sendcitycode = (citycode) => {
    console.log(citycode);
    this.setState({
      citycode: citycode,
    });
    axios
      .post(`https://www.udao56.cn/public/index.php/api/Commonapi/getBxdq`, {
        code: citycode,
      })
      .then(
        (res) => {
          let area = [
            {
              code: 0,
              value: "",
              label: "请选择",
              id: 0,
            },
          ];
          for (let i = 0; i < res.data.data.length; i++) {
            let obj = {
              code: res.data.data[i].code,
              value: res.data.data[i].code,
              label: res.data.data[i].area,
              id: res.data.data[i].id,
              pid: res.data.data[i].pid,
            };
            area.push(obj);
          }
          this.setState({
            area: area,
          });
          console.log(this.state.area);
          this.childs.myNames();
        },
        (error) => {
          console.log(error);
          this.info("接口调用失败，请重新尝试");
        }
      );
  };
  sendcitycodedd = (citycodedd) => {
    console.log(citycodedd);
    this.setState({
      citycodedd: citycodedd,
    });
    axios
      .post(`https://www.udao56.cn/public/index.php/api/Commonapi/getBxdq`, {
        code: citycodedd,
      })
      .then(
        (res) => {
          let areadd = [
            {
              code: 0,
              value: "",
              label: "请选择",
              id: 0,
            },
          ];
          for (let i = 0; i < res.data.data.length; i++) {
            let obj = {
              code: res.data.data[i].code,
              value: res.data.data[i].code,
              label: res.data.data[i].area,
              id: res.data.data[i].id,
              pid: res.data.data[i].pid,
            };
            areadd.push(obj);
          }
          this.setState({
            areadd: areadd,
          });
          console.log(this.state.areadd);
          this.child.myName();
        },
        (error) => {
          console.log(error);
          this.info("接口调用失败，请重新尝试");
        }
      );
  };
  onRef = (ref) => {
    this.child = ref;
  };
  onRef1 = (ref) => {
    this.childs = ref;
  };

  qdhideModal = () => {
    console.log(1111);
    //请求提交接口
    // axios.defaults.headers.common["token"] =
    //   "20dee61000cedf7efe7c2da03192738faz";
    axios
      .post(`http://cs.udao56.cn/public/index.php/api/Pingan/bdSearch`, {
        orderid: this.state.policyno,
        type:
          this.state.cpxz == 350
            ? "dj"
            : this.state.cpxz == 351
            ? "jb"
            : this.state.cpxz == 352
            ? "zh"
            : "", //zh 综合 jb 基本 dj大件三种
      })
      .then(
        (res) => {
          console.log(res.data);
          if (res.data.data.code == -1) {
            message.error("失败，" + res.data.info);
          } else {
            message.success("成功，" + res.data.info);
            let codeUrl = "";
            this.zhpdf(res.data.data);
          }
        },
        (error) => {
          console.log(error);
          this.info("接口调用失败，请重新尝试");
        }
      );
    this.setState({
      //   open: false,
    });
  };

  zhpdf = (data) => {
    axios
      .post(`https://www.udao56.cn/public/index.php/api/common/base64Img`, {
        pdf: data,
      })
      .then(
        (res) => {
          console.log(res.data.data.url);
          let url = res.data.data.url.split(".cn")[1];
          this.setState({
            policyimg: "https://www.udao56.cn" + url,
            open: false,
            // opendzbd: true,
          });

          const w = window.open("about:blank");
          w.location.href = "https://www.udao56.cn" + url;
          //刷新页面
          window.location.reload();
        },
        (error) => {
          console.log(error);
          this.info("接口调用失败，请重新尝试");
        }
      );
  };
  onDocumentComplete = (pages) => {
    //   setPage(1);
    this.setState({
      page: this.state.page + 1,
      pagetotal: this.state.pagetotal + 1,
    });
    // setPageTotal(pages);
  };

  xzhideModal = () => {
    // console.log("下载。。。");
    this.handlerDown(this.state.policyimg, "电子保单");
  };
  handlerDown = (url, name) => {
    const x = new XMLHttpRequest();
    const resourceUrl = url;
    x.open("GET", resourceUrl, true);
    x.responseType = "blob";
    x.onload = function () {
      const urls = window.URL.createObjectURL(x.response);
      const a = document.createElement("a");
      a.href = urls;
      a.download = name + ".pdf";
      a.click();
    };
    x.send();
    this.setState({
      opendzbd: false,
    });
  };
  qxhideModal = () => {
    console.log(2222);
    this.setState({
      open: false,
    });
    //刷新页面
    window.location.reload();
  };
  gbhideModal = () => {
    console.log(2222);
    this.setState({
      opendzbd: false,
    });
  };
  render() {
    return (
      <>
        <Header></Header>

        <div className="insure">
          <div className="insuretitle">
            <span>添加单票货运险</span>
            <span
              className="back"
              onClick={() => {
                window.location.href = "#/freightinsurance";
              }}
            >
              返回
            </span>{" "}
          </div>
          {/* 产品选择 */}
          <div className="productselection">
            <div
              className="title"
              onClick={() => {
                this.isshowcp();
              }}
            >
              <span>产品选择</span>
              <img
                src={bottom}
                width="15"
                height="8"
                style={{ transform: this.state.transform }}
                alt=""
              />{" "}
            </div>
            {/* style={{ display: this.state.isshow }} */}
            <div style={{ display: this.state.isshowcp }}>
              <div className="lx">
                <div className="lxtitle">
                  标的类型 <span className="bx">(*必选)</span>
                </div>
                <div className="radiobtn">
                  <Radiobtn sendFn={this.handleUpdate}></Radiobtn>
                </div>
              </div>
              <div className="lx">
                <div className="lxtitle">
                  产品选择
                  {/* <span className='bx'>(*必选)</span> */}
                </div>
                <div className="radiobtn2">
                  <Radiobtn2
                    sendValue={this.state.cpxzlx}
                    cpxzhandleUpdate={this.cpxzhandleUpdate}
                    showhidden2={this.showhidden2}
                  ></Radiobtn2>
                </div>
              </div>
              <div className="lx">
                <div className="lxtitle">
                  申明信息
                  <span
                    className="bx img"
                    onClick={() => {
                      this.showhidden();
                    }}
                  >
                    {" "}
                    <img
                      src={bottom}
                      width="15"
                      height="8"
                      style={{ transform: this.state.transform }}
                      alt=""
                    />{" "}
                  </span>
                </div>
                {/* {this.state.isshow}{this.state.cpxz} */}
                <div
                  className="sminfo"
                  style={{
                    display:
                      this.state.isshow == "block" && this.state.cpxz == 351
                        ? "block"
                        : "none",
                  }}
                >
                  承保基本险，适用《平安财险（备-货运）[2015]主46号-国内水路、陆
                  路货物运输保险条款》。
                  1.本保险每次事故绝对免赔人民币2000元或损失金额的10%，以高者为
                  准。火灾、爆炸、倾覆每次事故绝对免赔5000元或损失金额的15%，以
                  高者为准。
                  <br />
                  1.1、下列货物不属于本保险合同的保险标的，发生的任何损失，保险
                  人不负责赔偿：
                  <br />
                  1) 精密设备或仪器（精密设备的判断条件是：A.对
                  运输有特殊的防震动、防倾斜、防尘要求；B.受国内维修能力所限，设
                  备受损后无法在国内修复；C.单件货物价值超过人民币200万元；满足
                  A\B\C其中两条条件的标的即定义为精密设备或仪器）；
                  <br />
                  2) 大型港口
                  机械设备、大型铸件、风力发电相关设备、商品车、航空器、游艇、船
                  舶及有关运输设备；
                  <br />
                  3) 工艺品（包括各类雕刻、编织、刺绣、古董、
                  字画、瓷器、玉石等等）、木雕、红木家具及红木制品、原木、现金、
                  有价证券、珠宝、贵金属、发票、单证、首饰以及计算机软件等难以客
                  观确定保险价值的货物；
                  <br />
                  4) 散装粮食、鱼粉等散装货物；
                  <br />
                  5) 新鲜水
                  果、新鲜蔬菜、鲜活货品、鲜花、鲜活动植物、血液制品、疫苗；
                  <br />
                  6)
                  各种压缩气体、罐头食品、易燃易爆物品、白酒、国际（或国内）法律
                  法规规定的危险货物；
                  <br />
                  7) 国家法律、法规禁止邮寄或运输的物品；
                  <br />
                  8) 承运人或实际承运人的私人物品；
                  <br />
                  9)
                  易碎品（包括玻璃、玻璃制品、玻璃器皿、石膏制品、灯具、易碎工艺品、陶瓷、瓷砖、瓦、大理
                  石等等）、显像管、显示器、卫生洁具等。
                  <br />
                  10）汽车零配件
                  <br />
                  2、保险
                  标的按行业标准进行包装和捆扎，如无行业标准，则应采用足以保护标
                  的物的包装和捆扎方式，否则认定为“被保险人的故意行为或过失
                  ”；在包装运输前已损坏的货物，不在保险责任范围内。
                  <br />
                  3、保险标的
                  发生保险责任范围内的损失，保险金额等于或高于保险价值时，按实际
                  损失计算赔偿，最高不超过保险价值；保险金额低于保险价值时，按保
                  险金额与保险价值的比例乘以实际损失计算赔偿，最高不超过保险金额
                  。
                  <br />
                  4、公路运输时，保险标的必须用集装箱卡车、全封闭厢式卡车运输
                  或任何一辆用雨布全部覆盖并用绳索有效扎紧的适合长途运输的卡车装
                  运，否则本公司不承担缺少覆盖、遮盖造成的损失。
                  <br />
                  5、本保险不负责 外包装完好情况下被保标的的任何损失。
                  <br />
                  6、本保单运输范围为中国大
                  陆境内（除港澳、新疆、青海、西藏），超出运输范围不属于本保单赔
                  偿范围
                  <br />
                  7、本保单不承担自然灾害、装卸货期间导致的货物损失
                  <br />
                  8、保险人放弃对承运人 的追偿
                  <br />
                  本人已认真阅读所投{" "}
                  <span
                    className="blue"
                    onClick={() => {
                      window.open(
                        "http://adm.tongxingbao360.cn/main/policysl1"
                      );
                    }}
                  >
                    《国内水路，陆路货物运输保险条款》
                  </span>{" "}
                  、
                  <span
                    className="blue"
                    onClick={() => {
                      window.open(
                        "http://adm.tongxingbao360.cn/main/policypl2"
                      );
                    }}
                  >
                    《中国平安财产保险股份有限公司服务信息披露》
                  </span>{" "}
                  ，
                  充分理解条款的保险责任、责任免除、合同解除等内容。并且认真阅读并理解
                  <span
                    className="blue"
                    onClick={() => {
                      window.open("http://adm.tongxingbao360.cn/main/asgzs");
                    }}
                  >
                    《客户告知书》
                  </span>{" "}
                  、{" "}
                  <span
                    className="blue"
                    onClick={() => {
                      window.open("http://adm.tongxingbao360.cn/main/aswts");
                    }}
                  >
                    《电子化经纪服务委托协议书》
                  </span>{" "}
                  的全部内容。
                  <br />
                  本系统为系统自动处理，系统无法通过校验投保内容是否规范。请投保人务必根据协议要求进行投保，并如实填写。
                  若投保标的、承运工具、运输区间等相关信息不符合保险协议的约定，保险公司不承担保险责任。
                </div>
                <div
                  className="sminfo"
                  style={{
                    display:
                      this.state.isshow == "block" && this.state.cpxz == 352
                        ? "block"
                        : "none",
                  }}
                >
                  承保综合险，适用《平安财险（备-货运）[2015]主46号-国内水路、陆
                  路货物运输保险条款》。
                  1.本保险每次事故绝对免赔人民币2000元或损失金额的10%，以高者为
                  准。火灾、爆炸、倾覆每次事故绝对免赔5000元或损失金额的15%，以
                  高者为准。
                  <br />
                  1.1、下列货物不属于本保险合同的保险标的，发生的任何损失，保险
                  人不负责赔偿：
                  <br />
                  1) 精密设备或仪器（精密设备的判断条件是：A.对
                  运输有特殊的防震动、防倾斜、防尘要求；B.受国内维修能力所限，设
                  备受损后无法在国内修复；C.单件货物价值超过人民币200万元；满足
                  A\B\C其中两条条件的标的即定义为精密设备或仪器）；
                  <br />
                  2) 大型港口
                  机械设备、大型铸件、风力发电相关设备、商品车、航空器、游艇、船
                  舶及有关运输设备；
                  <br />
                  3) 工艺品（包括各类雕刻、编织、刺绣、古董、
                  字画、瓷器、玉石等等）、木雕、红木家具及红木制品、原木、现金、
                  有价证券、珠宝、贵金属、发票、单证、首饰以及计算机软件等难以客
                  观确定保险价值的货物；
                  <br />
                  4) 散装粮食、鱼粉等散装货物；
                  <br />
                  5) 新鲜水
                  果、新鲜蔬菜、鲜活货品、鲜花、鲜活动植物、血液制品、疫苗；
                  <br />
                  6)
                  各种压缩气体、罐头食品、易燃易爆物品、白酒、国际（或国内）法律
                  法规规定的危险货物；
                  <br />
                  7) 国家法律、法规禁止邮寄或运输的物品；
                  <br />
                  8) 承运人或实际承运人的私人物品；
                  <br />
                  9)
                  易碎品（包括玻璃、玻璃制品、玻璃器皿、石膏制品、灯具、易碎工艺品、陶瓷、瓷砖、瓦、大理
                  石等等）、显像管、显示器、卫生洁具等。
                  <br />
                  10）汽车零配件
                  <br />
                  2、保险
                  标的按行业标准进行包装和捆扎，如无行业标准，则应采用足以保护标
                  的物的包装和捆扎方式，否则认定为“被保险人的故意行为或过失
                  ”；在包装运输前已损坏的货物，不在保险责任范围内。
                  <br />
                  3、保险标的
                  发生保险责任范围内的损失，保险金额等于或高于保险价值时，按实际
                  损失计算赔偿，最高不超过保险价值；保险金额低于保险价值时，按保
                  险金额与保险价值的比例乘以实际损失计算赔偿，最高不超过保险金额
                  。
                  <br />
                  4、公路运输时，保险标的必须用集装箱卡车、全封闭厢式卡车运输
                  或任何一辆用雨布全部覆盖并用绳索有效扎紧的适合长途运输的卡车装
                  运，否则本公司不承担缺少覆盖、遮盖造成的损失。
                  <br />
                  5、本保险不负责 外包装完好情况下被保标的的任何损失。
                  <br />
                  6、本保单运输范围为中国大
                  陆境内（除港澳、新疆、青海、西藏），超出运输范围不属于本保单赔
                  偿范围
                  <br />
                  7、雨淋、自然灾害、装卸货事故导致的货物损失, 每次事故绝
                  对免赔10000元或损失金额的20%，以高者为准
                  <br />
                  8、保险人放弃对承运人 的追偿
                  <br />
                  9、薄膜木架等简易包装和不规则包装以及裸装货物、二手货物
                  和旧货物，仅承保因运输工具发生碰撞、倾覆和火灾事故造成所载货物
                  的直接损失；且每次事故绝对免赔10000元或损失金额的20%，以高者为准
                  <br />
                  释义： 1）碰
                  撞是指运输工具在运输中与该运输工具以外的其他物体直接相撞，或在
                  静止（如停靠等）状态下受到该运输工具以外的运动物体直接相撞。
                  2）倾覆是指运输工具在行驶中，车身翻倒或倾侧，失去正常状态，非
                  经施救不能继续行驶
                  <br />
                  本人已认真阅读所投{" "}
                  <span
                    className="blue"
                    onClick={() => {
                      window.open(
                        "http://adm.tongxingbao360.cn/main/policysl1"
                      );
                    }}
                  >
                    《国内水路，陆路货物运输保险条款》
                  </span>{" "}
                  、
                  <span
                    className="blue"
                    onClick={() => {
                      window.open(
                        "http://adm.tongxingbao360.cn/main/policypl2"
                      );
                    }}
                  >
                    《中国平安财产保险股份有限公司服务信息披露》
                  </span>{" "}
                  ，
                  充分理解条款的保险责任、责任免除、合同解除等内容。并且认真阅读并理解
                  <span
                    className="blue"
                    onClick={() => {
                      window.open("http://adm.tongxingbao360.cn/main/asgzs");
                    }}
                  >
                    《客户告知书》
                  </span>{" "}
                  、{" "}
                  <span
                    className="blue"
                    onClick={() => {
                      window.open("http://adm.tongxingbao360.cn/main/aswts");
                    }}
                  >
                    《电子化经纪服务委托协议书》
                  </span>{" "}
                  的全部内容。
                  <br />
                  本系统为系统自动处理，系统无法通过校验投保内容是否规范。请投保人务必根据协议要求进行投保，并如实填写。
                  若投保标的、承运工具、运输区间等相关信息不符合保险协议的约定，保险公司不承担保险责任。
                </div>
                <div
                  className="sminfo"
                  style={{
                    display:
                      this.state.isshow == "block" && this.state.cpxz == 350
                        ? "block"
                        : "none",
                  }}
                >
                  承保险种：承保基本险，适用《平安财险（备-货运）[2015]主46号-国内水路、陆
                  路货物运输保险条款》。
                  (本保单运输范围为中国大陆境内（除港澳、新疆、青海、西藏）)
                  单次运输限额：最高500万
                  <br />
                  免赔：每次事故绝对免赔额RMB5000元或损失金额的10%，以高者为准；火灾、车辆倾覆每次事故绝对免赔额RMB1万或损失金额的15%，以高者为准；
                  <br />
                  特别约定：
                  <br />
                  1、本保单仅承担因运输工具发生碰撞、倾覆造成所载货物的直接损失；
                  <br />
                  释义：1）碰撞是指运输工具在运输中与该运输工具以外的其他物体直接相撞，或在静止（如停靠等）状态下受到该运输工具以外的运动物体直接相撞。
                  <br />
                  2）倾覆是指运输工具在行驶中，车身翻倒或倾侧，失去正常状态，非经施救不能继续行驶。
                  <br />
                  2、若运输工具未与外界其他物体发生碰撞事故，仅货物与运输工具之间碰撞，不属于本保单保障范围
                  <br />
                  3、承运人必须具备大型物件运输资质，否则保险人不承担赔偿责任
                  <br />
                  4、 本保单不承担装卸货、下井责任
                  <br />
                  5、
                  本保单仅承保公路运输期间因发生保险事故而导致的损失，如在内部施工场地、山地等不利地形发生的保险事故不在承保范围之内
                  <br />
                  6、
                  商品车、农机、电动车、二手已受损货物，龙门吊、港机、船吊、大型变压器（变频器的额定电压110KV及以上或重量40TON及以上）
                  不在本保单承保范围内，7、本保
                  单运输范围为中国大陆境内（除港澳、新疆、青海、西藏），超出运输范围不属于本保单赔偿范围
                  8、符合以下任何一条的货物，不属于本保 单保障范围：
                  （1）对于运输有特别的防震动、防倾斜、防尘、温控等 特殊要求；
                  （2）设备受损后因国内不具备修复能力需运到国外修理；
                  （3）单件货物价格超过RMB500万。
                </div>
              </div>
            </div>
          </div>

          <div className="bxinfo">
            <div
              className="title"
              onClick={() => {
                this.isshowbxinfo();
              }}
            >
              <span>保险人信息</span>
              <img
                src={bottom}
                width="15"
                height="8"
                style={{ transform: this.state.transform }}
                alt=""
              />{" "}
            </div>
            <div
              style={{
                display:
                  this.state.isshowbxinfo == "block" &&
                  this.state.isshowbx == "none"
                    ? "block"
                    : "none",
              }}
            >
              <div className="input input2">
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">被保人</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.beneficiary = e;
                      }}
                      placeholder="请填写被保人"
                    />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">被保人联系人</span>
                    {/* <span className="bt">*必填</span> */}
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.belink = e;
                      }}
                      placeholder="请填写被保人联系人"
                    />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">被保人联系地址</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.beaddress = e;
                      }}
                      placeholder="请填写被保人联系地址"
                    />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">被保人联系电话</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.bephone = e;
                      }}
                      placeholder="请填写被保人联系电话"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display:
                  this.state.isshowbxinfo == "block" &&
                  this.state.isshowbx == "block"
                    ? "block"
                    : "none",
              }}
            >
              <div className="input input2">
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">投保人类型</span>
                  </div>
                  <div className="bottom">
                    <Selectchoise bindTodo={this.bindTodo}></Selectchoise>
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">投保人手机号码</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.tbphone = e;
                      }}
                      placeholder="请填写手机号码"
                    />
                  </div>
                </div>

                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">投保人姓名</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.tbname = e;
                      }}
                      placeholder="请填写投保人姓名"
                    />
                  </div>
                </div>
                <div
                  className="bxinput"
                  style={{ display: this.state.isshowtblx }}
                >
                  <div className="top">
                    <span className="bxname">投保人证件类型</span>
                  </div>
                  <div className="bottom">
                    <Selectchoisetblx></Selectchoisetblx>
                  </div>
                </div>
                <div
                  className="bxinput"
                  style={{ display: this.state.isshowtblx }}
                >
                  <div className="top">
                    <span className="bxname">投保人证件号码</span>
                    {/* <span className="bt">*必填</span> */}
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.tbsfz = e;
                      }}
                      placeholder="请填写投保人证件号码"
                    />
                  </div>
                </div>

                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">被保人类型</span>
                  </div>
                  <div className="bottom">
                    <Selectbbchoise
                      bbbindTodo={this.bbbindTodo}
                    ></Selectbbchoise>
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">被保人姓名</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.bbname = e;
                      }}
                      placeholder="请填写被保人姓名"
                    />
                  </div>
                </div>
                <div
                  className="bxinput"
                  style={{ display: this.state.isshowbblx }}
                >
                  <div className="top">
                    <span className="bxname">被保人证件类型</span>
                  </div>
                  <div className="bottom">
                    <Selectchoisebblx></Selectchoisebblx>
                  </div>
                </div>
                <div
                  className="bxinput"
                  style={{ display: this.state.isshowbblx }}
                >
                  <div className="top">
                    <span className="bxname">被保人证件号码</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.bbsfz = e;
                      }}
                      placeholder="请填写被保人证件号码"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bxinfo">
            <div
              className="title"
              onClick={() => {
                this.isshowysinfo();
              }}
            >
              <span>运输信息</span>
              <img
                src={bottom}
                width="15"
                height="8"
                style={{ transform: this.state.transform }}
                alt=""
              />{" "}
            </div>
            <div style={{ display: this.state.isshowysinfo }}>
              <div className="input">
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">车牌号</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.licensePlate = e;
                      }}
                      placeholder="请填写车牌号"
                    />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">起运地</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Citychose
                      ref="citycode"
                      citycode={this.sendcitycode}
                    ></Citychose>
                    <Areachose
                      onRef1={this.onRef1}
                      area={this.state.area}
                      ref="areacode"
                    ></Areachose>
                  </div>
                </div>

                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">到达地</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Citychosedd
                      ref="citycodedd"
                      citycodedd={this.sendcitycodedd}
                    ></Citychosedd>
                    <Areachosedd
                      onRef={this.onRef}
                      areadd={this.state.areadd}
                      ref="areacodedd"
                    ></Areachosedd>
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">起运时间</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom bottom2">
                    <Date ref="datestring"></Date>
                    <Input
                      style={{ width: "37%" }}
                      ref={(e) => {
                        this.state.form.cargoFromDateHr = e;
                      }}
                      placeholder="起运小时(24小时)"
                    />
                  </div>
                </div>

                <div className="bxinput"></div>
              </div>
            </div>
          </div>
          <div className="bxinfo">
            <div
              className="title"
              onClick={() => {
                this.isshowhwinfo();
              }}
            >
              <span>货物信息</span>
              <img
                src={bottom}
                width="15"
                height="8"
                style={{ transform: this.state.transform }}
                alt=""
              />{" "}
            </div>
            <div style={{ display: this.state.isshowhwinfo }}>
              <div className="input">
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">货物的描述以及数量</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.cargoDesc = e;
                      }}
                      placeholder="请填写货物的描述以及数量"
                    />
                  </div>
                </div>

                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">包装方式</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Bzchose ref="bzchose"></Bzchose>
                    {/* <Input placeholder="请填写货物重量" /> */}
                  </div>
                </div>

                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">保险金额</span>
                    <span className="bt">
                      最小保额:￥{this.state.cpxz == 351 ? "1,000" : "10,000"}元
                    </span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.amountpa = e;
                      }}
                      placeholder="请输入保险金额保额（元）"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tjbtn">
            <Button
              type="primary"
              loading={this.state.loading}
              danger
              onClick={this.formbtn}
            >
              确定提交
            </Button>
            <Button
              onClick={() => {
                window.location.href = "#/freightinsurance";
              }}
            >
              返回
            </Button>
            <Modal
              title="投保成功"
              open={this.state.open}
              onOk={this.qdhideModal}
              onCancel={this.qxhideModal}
              okText="获取电子保单"
              cancelText="取消"
            >
              <p>保单号：{this.state.policyno}</p>
              <p>
                保额类型：
                {this.state.cpxz == 350
                  ? "大件险"
                  : this.state.cpxz == 351
                  ? "普货险"
                  : this.state.cpxz == 352
                  ? "综合险"
                  : ""}
              </p>
              <p>保额：{this.state.price}</p>
            </Modal>
            <Modal
              title="电子保单"
              open={this.state.opendzbd}
              onOk={this.xzhideModal}
              onCancel={this.gbhideModal}
              okText="下载电子保单"
              cancelText="取消"
              width={1200}
            >
              <PDF
                width={"1000px"}
                file={this.state.policyimg}
                onDocumentComplete={() => {
                  this.onDocumentComplete();
                }}
                page={1}
              />
              {this.state.pagetotal
                ? new Array(this.state.pagetotal)
                    .fill("")
                    .map((item, index) => {
                      return index + 1 !== 1 ? (
                        <PDF file={this.state.policyimg} page={index + 1} />
                      ) : (
                        ""
                      );
                    })
                : ""}
            </Modal>
          </div>
        </div>

        {/* <Image
          width={200}
          src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        /> */}

        <Footer></Footer>
      </>
    );
  }
}
export default Insure2;
