import React, { Component } from "react";
import { Button, Tooltip, Space, Input } from "antd";
import Header from "../../components/header";
import Footer from "../../components/footer";

import item1 from "../../assets/images/zxfw/item1.png";
import item2 from "../../assets/images/zxfw/item2.png";
import item3 from "../../assets/images/zxfw/item3.png";

import "../../assets/css/zzfw.css";

export class Freightinsurance extends Component {
  // golink() {
  //     console.log(11);
  //     // dispatch(push("/freightinsurance"));
  // }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header></Header>

        <div className="freightinsurance">
          <div className="productioncon"></div>
        </div>
        <div className="freightitem">
          <div className="itemcon">
            {/* <div
              className="item"
              onClick={() => {
                window.location.href =
                  "http://adm.tongxingbao360.cn/?m=insurance&a=add&i=9";
              }}
            > */}
            <div
              className="item"
              onClick={() => {
                window.location.href = "#/painsure";
              }}
            >
              <div className="topimg">
                <img src={item3} width="140" height="90" alt="" />
              </div>
              <div className="title">货物保险</div>
              <div className="con">一车一保，一票一保，按货量投保</div>
            </div>
            <div className="item">
              <div className="topimg">
                <img src={item2} width="140" height="90" alt="" />
              </div>
              <div className="title">车辆保险</div>
              <div className="con">叉车保险、年度保险、车上货物保险</div>
            </div>
            <div className="item">
              <div className="topimg">
                <img src={item1} width="140" height="90" alt="" />
              </div>
              <div className="title">人员保险</div>
              <div className="con">雇主责任险、司机意外险、司机驾乘雇主险</div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    );
  }
}
export default Freightinsurance;
