import React, { Component } from "react";
import { Button, Tooltip, Space, Input } from "antd";
import Header from "../../components/header";
import Footer from "../../components/footer";

import first from "../../assets/images/production/qy1.png";
import second from "../../assets/images/production/qy2.png";
import third from "../../assets/images/production/qy3.png";
import title3 from "../../assets/images/production/title3.png";

import "../../assets/css/quyuzx.css";

export class Quyuzx extends Component {
  constructor(props) {
    super(props);
    //   this.state = { alertStatus: false };
    this.Header = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  sqsy = () => {
    //调用父组件中的函数
    this.Header.current.sqsy();
  };
  render() {
    return (
      <>
        <Header ref={this.Header}></Header>

        <div className="production2">
          <div className="productioncon">
            <div className="left">
              <div className="titleimg">
                <img src={title3} width="300" height="40" alt="" />
              </div>
              <div className="centercon">
                多线路运营管理/一票一结算/加盟线路业务互通
              </div>
              <div
                className="btn"
                onClick={() => {
                  this.sqsy();
                }}
              >
                <Button type="primary">申请试用</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="introduce">
          <div className="introducecon">
            <div className="first">
              <div className="introduceimg">
                <img src={first} width="600" height="300" alt="" />
              </div>
              <div className="introducetext">
                <div className="text">1. 多线路运营管理</div>
                <div className="text text2">
                  多条加盟线路，业务独立运营，财务总部管理
                </div>
              </div>
            </div>
            <div className="first">
              <div className="introducetext">
                <div className="text">2. 一票一结算降低财务风险</div>
                <div className="text text2">
                  底价模式、按比例分分成自由搭配，支持同一公司不同结算方式
                </div>
                {/* <div className='text'>
                                    增加客户粘性
                                </div> */}
              </div>
              <div className="introduceimg">
                <img src={second} width="600" height="300" alt="" />
              </div>
            </div>
            <div className="first">
              <div className="introduceimg">
                <img src={third} width="600" height="300" alt="" />
              </div>
              <div className="introducetext">
                <div className="text">3. 加盟线路业务互通</div>
                <div className="text text2">
                  一票到底全程轨迹跟踪，各线中之间自动结算
                </div>
                {/* <div className='text'>
                                    自动清分
                                </div>
                                <div className='text'>
                                    增加货物涞源
                                </div> */}
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </>
    );
  }
}
export default Quyuzx;
