import React, { Component } from "react";
import {
  Button,
  Tooltip,
  Space,
  Input,
  Radio,
  DatePicker,
  TimePicker,
  Upload,
  message,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import locale from "antd/es/date-picker/locale/zh_CN";
import Header from "../../components/header";
import Footer from "../../components/footer";

import bottom from "../../assets/images/home/bottom.png";

// import '../../assets/css/zzfw.css'
import "../../assets/css/insure.css";

class Radiobtn extends Component {
  constructor(props) {
    super(props);
    this.state = { bdlx: 1, inputValue: "" };
  }

  onChange = (e) => {
    console.log("radio checked", e.target.value);
    const { sendFn } = this.props;
    sendFn(e.target.value);

    this.setState({
      bdlx: e.target.value,
    });
  };
  render() {
    return (
      <Radio.Group onChange={this.onChange} value={this.state.bdlx}>
        <Radio value={1}>商品车•轿车运输险</Radio>
        <Radio value={2}>鲜活冷藏保险</Radio>
        <Radio value={3}>普货保险</Radio>
        <Radio value={4}>邮包货险</Radio>
        <Radio value={5}>易碎险</Radio>
        <Radio value={6}>大件运输险</Radio>
        <Radio value={7}>城际险</Radio>
        <Radio value={8}>危货品</Radio>
        <Radio value={9}>四川专线方案</Radio>
      </Radio.Group>
    );
  }
}

class Radiobtn2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cpxz: 0,
    };
  }

  onChange = (e) => {
    const { cpxzhandleUpdate, showhidden2 } = this.props;
    console.log("radio checked", e.target.value);
    this.setState({
      cpxz: e.target.value,
    });
    cpxzhandleUpdate(e.target.value);
    showhidden2();
  };
  render() {
    const { sendValue } = this.props;
    return (
      <Radio.Group onChange={this.onChange} value={this.state.cpxz}>
        {sendValue?.map((item, index) => {
          return <Radio value={index + 1}>{item}</Radio>;
        })}
      </Radio.Group>
    );
  }
}

//选择时间
const onDateChange = (date, dateString) => {
  console.log(date, dateString);
};
const onTimeChange = () => {
  console.log(123);
};
const Date = () => {
  const [type, setType] = useState("time");

  return (
    <>
      <DatePicker
        locale={locale}
        style={{ width: "62%" }}
        onChange={onDateChange}
      />

      <TimePicker
        locale={locale}
        style={{ width: "36%" }}
        onChange={onTimeChange}
      />
    </>
  );
};

//上传视频
const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
const Uploadradio = () => (
  <Upload {...props}>
    <Button icon={<UploadOutlined />}>上传视频</Button>
  </Upload>
);

//下拉选择
// const handleChange = (value) => {
//   console.log(`selected ${value}`);
// };

const zjlxhandleChange = (value) => {
  console.log(`selected ${value}`);

  if (value.indexOf("投保") != -1) {
    console.log(11);
  }
};

//投保证件类型
class Selectchoise extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    this.props.bindTodo(value);
  }

  render() {
    return (
      <Select
        defaultValue="个人"
        style={{ width: "100%" }}
        onChange={this.handleChange}
        options={[
          { value: "个人", label: "个人" },
          { value: "公司", label: "公司" },
        ]}
      />
    );
  }
}
//投保证件类型
const Selectchoisetblx = () => (
  <Select
    defaultValue="身份证"
    style={{ width: "100%" }}
    onChange={zjlxhandleChange}
    options={[
      { value: "投保身份证", label: "身份证" },
      { value: "投保其他", label: "其他" },
    ]}
  />
);
//被保证件类型
const Selectchoisebblx = () => (
  <Select
    defaultValue="身份证"
    style={{ width: "100%" }}
    onChange={zjlxhandleChange}
    options={[
      { value: "被保身份证", label: "身份证" },
      { value: "被保其他", label: "其他" },
    ]}
  />
);

//被保证件类型
class Selectbbchoise extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    this.props.bbbindTodo(value);
  }

  render() {
    return (
      <Select
        defaultValue="个人"
        style={{ width: "100%" }}
        onChange={this.handleChange}
        options={[
          { value: "个人", label: "个人" },
          { value: "公司", label: "公司" },
        ]}
      />
    );
  }
}

export class Insure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isshow: "none",
      transform: "rotate(0deg)",
      isshowcp: "block",
      isshowbxinfo: "block",
      isshowysinfo: "block",
      isshowhwinfo: "block",
      isshowtblx: "block", //投保类型，个人时显示投保人证件号，公司时不显示
      isshowbblx: "block", //被保类型，个人时显示投保人证件号，公司时不显示
      inputValue: "",
      cpxzlx: ["人保•商品车运输险", "人保•轿车运输险"],
      cpxz: 0,
      isshowbx: "block",
      form: {
        tbphone: "",
        tbname: "",  
        tbsfz: "",
        bbname: "",
        bbsfz: "",
      },
    };
    this.bindTodo = this.bindTodo.bind(this);
    this.bbbindTodo = this.bbbindTodo.bind(this);
  }
  bindTodo() {
    if (this.state.isshowtblx == "none") {
      this.setState({
        isshowtblx: "block",
      });
    }
    if (this.state.isshowtblx == "block") {
      this.setState({
        isshowtblx: "none",
      });
    }
  }
  bbbindTodo() {
    if (this.state.isshowbblx == "none") {
      this.setState({
        isshowbblx: "block",
      });
    }
    if (this.state.isshowbblx == "block") {
      this.setState({
        isshowbblx: "none",
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  showhidden() {
    console.log(this.state.isshow); //cpxz
    console.log(this.state.cpxz); //cpxz
    if (this.state.isshow == "none" && this.state.cpxz != 0) {
      this.setState({
        isshow: "block",
        transform: "rotate(180deg)",
      });
    }
    if (this.state.isshow == "block") {
      this.setState({
        isshow: "none",
        transform: "rotate(0deg)",
      });
    }
  }
  showhidden2 = () => {
    console.log(123);
    this.setState({
      isshow: "block",
      transform: "rotate(180deg)",
      isshowbx: "none", //判断保险类型的选择
    });
  };

  isshowcp() {
    if (this.state.isshowcp == "none") {
      this.setState({
        isshowcp: "block",
      });
    }
    if (this.state.isshowcp == "block") {
      this.setState({
        isshowcp: "none",
      });
    }
  }
  isshowbxinfo() {
    if (this.state.isshowbxinfo == "none") {
      this.setState({
        isshowbxinfo: "block",
      });
    }
    if (this.state.isshowbxinfo == "block") {
      this.setState({
        isshowbxinfo: "none",
      });
    }
  }
  isshowysinfo() {
    if (this.state.isshowysinfo == "none") {
      this.setState({
        isshowysinfo: "block",
      });
    }
    if (this.state.isshowysinfo == "block") {
      this.setState({
        isshowysinfo: "none",
      });
    }
  }
  isshowhwinfo() {
    if (this.state.isshowhwinfo == "none") {
      this.setState({
        isshowhwinfo: "block",
      });
    }
    if (this.state.isshowhwinfo == "block") {
      this.setState({
        isshowhwinfo: "none",
      });
    }
  }

  isshowtblx() {
    if (this.state.isshowtblx == "none") {
      this.setState({
        isshowtblx: "block",
      });
    }
    if (this.state.isshowtblx == "block") {
      this.setState({
        isshowtblx: "none",
      });
    }
  }

  cpxzhandleUpdate = (inputValue) => {
    console.log(inputValue);
    this.setState({
      cpxz: inputValue,
    });
  };

  handleUpdate = (inputValue) => {
    console.log(inputValue);

    if (inputValue == 1) {
      this.setState({
        cpxzlx: ["人保•商品车运输险", "人保•轿车运输险"],
      });
    }
    if (inputValue == 2) {
      this.setState({
        cpxzlx: ["人保•果蔬险", "人保•鲜活冷藏险", "平安•果蔬险"],
      });
    }
    if (inputValue == 3) {
      this.setState({
        cpxzlx: [
          "人保•普货运输险",
          "中路•综合险",
          "中路•基本险",
          "平安•综合险",
          "平安•基本险",
          "平南京人保•综合险",
          "南京人保•基本险",
        ],
      });
    }
    if (inputValue == 4) {
      this.setState({
        cpxzlx: ["人保•港澳台邮包险", "人保•国际邮包险", "人保•国内邮包险"],
      });
    }
    if (inputValue == 5) {
      this.setState({
        cpxzlx: ["人保•易碎运输险", "平安•易碎险", "山东平安•易碎品险"],
      });
    }
    if (inputValue == 6) {
      this.setState({
        cpxzlx: ["人保•大件运输险", "平安大件•风电运输险", "平安•大件险"],
      });
    }
    if (inputValue == 7) {
      this.setState({
        cpxzlx: ["中路•城际综合险", "中路•城际基本险", "平安•城际基本险"],
      });
    }
    if (inputValue == 8) {
      this.setState({
        cpxzlx: ["平安*危货品综合险", "平安*危货品基本险"],
      });
    }
    if (inputValue == 9) {
      this.setState({
        cpxzlx: [
          "国寿财•易碎险（新疆、西藏）",
          "国寿财•综合险（新疆、西藏）",
          "国寿财•易碎险(普通路线)",
          "国寿财•大件(新疆、西藏线路)",
          "国寿财•大件(普通路线)",
          "国寿财•综合险(普通路线)",
        ],
      });
    }
    console.log(this.state.cpxzlx);
  };

  //form提交
  formbtn = () => {
    console.log(123);
    console.log(this.state.form);
  };

  render() {
    return (
      <>
        <Header></Header>

        <div className="insure">
          <div className="insuretitle">添加单票货运险</div>
          {/* 产品选择 */}
          <div className="productselection">
            <div
              className="title"
              onClick={() => {
                this.isshowcp();
              }}
            >
              <span>产品选择</span>
              <img
                src={bottom}
                width="15"
                height="8"
                style={{ transform: this.state.transform }}
                alt=""
              />{" "}
            </div>
            {/* style={{ display: this.state.isshow }} */}
            <div style={{ display: this.state.isshowcp }}>
              <div className="lx">
                <div className="lxtitle">
                  标的类型 <span className="bx">(*必选)</span>
                </div>
                <div className="radiobtn">
                  <Radiobtn sendFn={this.handleUpdate}></Radiobtn>
                </div>
              </div>
              <div className="lx">
                <div className="lxtitle">
                  产品选择
                  {/* <span className='bx'>(*必选)</span> */}
                </div>
                <div className="radiobtn2">
                  <Radiobtn2
                    sendValue={this.state.cpxzlx}
                    cpxzhandleUpdate={this.cpxzhandleUpdate}
                    showhidden2={this.showhidden2}
                  ></Radiobtn2>
                </div>
              </div>
              <div className="lx">
                <div className="lxtitle">
                  申明信息
                  <span
                    className="bx img"
                    onClick={() => {
                      this.showhidden();
                    }}
                  >
                    {" "}
                    <img
                      src={bottom}
                      width="15"
                      height="8"
                      style={{ transform: this.state.transform }}
                      alt=""
                    />{" "}
                  </span>
                </div>
                <div className="sminfo" style={{ display: this.state.isshow }}>
                  承保险别：国内公路货物运输保险 商品车运输险
                  每保单、最低保额10万起 ；
                  本险种保额100万以下在线自核，保额超过100万的可以在线提单联系管理员人工核保
                  <br />
                  投保时间：24小时(全天) 批单时间：保单起运时间之前
                  <br />
                  1.本产品承保的运输路线为中华人民共和国境内(不包括港澳台地区）。
                  <br />
                  2.保险责任：下列保险事故造成保险货物的损失和费用保险人依照本条款约定负责赔偿：
                  <br />
                  （一）火灾、爆炸、雷电、冰雹、暴风、暴雨、洪水、海啸、地陷、崖崩、突发性滑坡、泥石流；
                  <br />
                  （二）由于运输工具发生碰撞、倾覆或隧道、码头坍塌，或在驳运过程中因驳运工具遭受搁浅、触礁、沉没、碰撞；
                  <br />
                  （三）在装货、卸货或转载时因意外事故造成的损失；
                  <br />
                  （四）因碰撞、挤压而造成货物破碎、弯曲、凹瘪、折断、开裂的损失；
                  <br />
                  （五）因包装破裂致使货物散失的损失；
                  <br />
                  （六）液体货物因受碰撞或挤压致使所用容器（包括封口)损坏而渗漏的损失，或用液体保藏的货物因液体渗漏而造成该货物腐烂变质的损失；
                  <br />
                  （七）符合安全运输规定而遭受雨淋所致的损失；
                  <br />
                  （八）在发生上述灾害事故时，因纷乱造成货物的散失以及因施救或保护货物所支付的直接合理的费用。
                  <br />
                  3.
                  免赔额：每次事故绝对免赔为人民币3000元或者损失金额的10%，两者以高者为准；商品车的玻璃单独破碎及车身划痕责任除外。
                  <br />
                  4. 承保标的
                  <br />{" "}
                  本保单承保以下货物：各类商品车、二手车、私家车、展览车、农用车、摩托车。
                  <br />
                  5.本保险由中国人民财产保险股份有限公司承保
                  ；报案电话95518；出险后必须在48小时内通知查勘定损，如因被保险人原因造成事故无法定损，保险公司不承担事故赔偿责任。
                  <br />
                  6.本人已认真阅读所投{" "}
                  <span className="blue">《国内公路货物运输保险条款》</span> 、
                  <span className="blue">
                    《中国人民财产保险股份有限公司服务信息披露》
                  </span>
                  ，
                  充分理解条款的保险责任、责任免除、合同解除等内容。并且认真阅读并理解
                  <span className="blue">《客户告知书》</span>、{" "}
                  <span className="blue">《电子化经纪服务委托协议书》</span>{" "}
                  的全部内容。
                </div>
              </div>
            </div>
          </div>

          <div className="bxinfo">
            <div
              className="title"
              onClick={() => {
                this.isshowbxinfo();
              }}
            >
              <span>保险人信息</span>
              <img
                src={bottom}
                width="15"
                height="8"
                style={{ transform: this.state.transform }}
                alt=""
              />{" "}
            </div>
            <div
              style={{
                display:
                  this.state.isshowbxinfo == "block" &&
                  this.state.isshowbx == "none"
                    ? "block"
                    : "none",
              }}
            >
              <div className="input">
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">投保人</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="请填写投保人" />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">被保人</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="请填写被保人" />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">投保证件号</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="个人 填写身份证 公司填写统一社会信用代码" />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display:
                  this.state.isshowbxinfo == "block" &&
                  this.state.isshowbx == "block"
                    ? "block"
                    : "none",
              }}
            >
              <div className="input input2">
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">投保人类型</span>
                    {/* <span className="bt">*必填</span> */}
                  </div>
                  <div className="bottom">
                    <Selectchoise bindTodo={this.bindTodo}></Selectchoise>
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">投保人手机号码</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.tbphone = e;
                      }}
                      placeholder="请填写手机号码"
                    />
                  </div>
                </div>

                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">投保人姓名</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.tbname = e;
                      }}
                      placeholder="请填写投保人姓名"
                    />
                  </div>
                </div>
                <div
                  className="bxinput"
                  style={{ display: this.state.isshowtblx }}
                >
                  <div className="top">
                    <span className="bxname">投保人证件类型</span>
                  </div>
                  <div className="bottom">
                    <Selectchoisetblx></Selectchoisetblx>
                  </div>
                </div>
                <div
                  className="bxinput"
                  style={{ display: this.state.isshowtblx }}
                >
                  <div className="top">
                    <span className="bxname">投保人证件号码</span>
                    {/* <span className="bt">*必填</span> */}
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.tbsfz = e;
                      }}
                      placeholder="请填写投保人证件号码"
                    />
                  </div>
                </div>

                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">被保人类型</span>
                  </div>
                  <div className="bottom">
                    <Selectbbchoise
                      bbbindTodo={this.bbbindTodo}
                    ></Selectbbchoise>
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">被保人姓名</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.bbname = e;
                      }}
                      placeholder="请填写被保人姓名"
                    />
                  </div>
                </div>
                <div
                  className="bxinput"
                  style={{ display: this.state.isshowbblx }}
                >
                  <div className="top">
                    <span className="bxname">被保人证件类型</span>
                  </div>
                  <div className="bottom">
                    <Selectchoisebblx></Selectchoisebblx>
                  </div>
                </div>
                <div
                  className="bxinput"
                  style={{ display: this.state.isshowbblx }}
                >
                  <div className="top">
                    <span className="bxname">被保人证件号码</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input
                      ref={(e) => {
                        this.state.form.bbsfz = e;
                      }}
                      placeholder="请填写被保人证件号码"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bxinfo">
            <div
              className="title"
              onClick={() => {
                this.isshowysinfo();
              }}
            >
              <span>运输信息</span>
              <img
                src={bottom}
                width="15"
                height="8"
                style={{ transform: this.state.transform }}
                alt=""
              />{" "}
            </div>
            <div style={{ display: this.state.isshowysinfo }}>
              <div className="input">
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">车牌号/运输工具号</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="请填写车牌号/运输工具号" />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">起运地描述</span>
                    <span className="bt">*必填 省+市 例如山东淄博</span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="请填写起运地描述 必须为省+市" />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">中转地</span>
                    <span className="bt">*必填 省+市 例如山东淄博</span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="请填写中转地描述 必须为省+市" />
                  </div>
                </div>

                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">目的地</span>
                    <span className="bt">*必填 省+市 例如山东淄博</span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="请填写目的地描述 必须为省+市" />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">起运时间</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom bottom2">
                    <Date></Date>
                  </div>
                </div>
                <div className="bxinput"></div>
              </div>
            </div>
          </div>
          <div className="bxinfo">
            <div
              className="title"
              onClick={() => {
                this.isshowhwinfo();
              }}
            >
              <span>货物信息</span>
              <img
                src={bottom}
                width="15"
                height="8"
                style={{ transform: this.state.transform }}
                alt=""
              />{" "}
            </div>
            <div style={{ display: this.state.isshowhwinfo }}>
              <div className="input">
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">货物名称</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="请填写货物名称" />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">货物数量</span>
                    <span className="bt">*必填 为数字</span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="请填写货物数量" />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">货物重量</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="请填写货物重量" />
                  </div>
                </div>

                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">保险金额</span>
                    <span className="bt">
                      最小保额:￥10,000元 剩余信用额度:0.00元
                    </span>
                  </div>
                  <div className="bottom">
                    <Input placeholder="请输入保险金额保额（元）" />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">所属用户</span>
                    <span className="bt">*必填</span>
                  </div>
                  <div className="bottom bottom2">
                    <Input disabled />
                  </div>
                </div>
                <div className="bxinput">
                  <div className="top">
                    <span className="bxname">视频</span>
                    <span className="bt">*易碎品 必传</span>
                  </div>
                  <div className="bottom bottom2">
                    <Uploadradio></Uploadradio>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tjbtn">
            <Button type="primary" danger onClick={this.formbtn}>
              确定提交
            </Button>
            <Button>返回</Button>
          </div>
        </div>

        {/* <div className='insure'>
                    
                    调用别人的页面嵌入自己的页面
                    <iframe
                        // src={"http://adm.tongxingbao360.cn"}
                        src={"https://www.udao56.cn/"}
                        height={"600px"}
                        width={"100%"}
                        name="iframe-video"
                    />
                </div> */}
        <Footer></Footer>
      </>
    );
  }
}
export default Insure;
