import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { homeRoute } from './router/routers';
import Attacks from './pages/attack';
import Production from './pages/productintroduction';
import Quyuzx from './pages/quyuzx';
import Innergl from './pages/innergl'; 
import Freightinsurance from './pages/freightinsurance';    //freightinsurance
import Advertising from './pages/advertising';  
import Aboutus from './pages/aboutus';  
import Logistics from './pages/logistics';      //Logistics
import Insure from './pages/insure';    //insure  
import Insure2 from './pages/insure2';    //insure  

ReactDOM.render(
    <Router>
        <Switch>
            <Route path="/admin" component={App}/>
            <Route path="/attack" component={Attacks}/>
            <Route path="/production" component={Production}/>
            <Route path="/dedicatedline" component={Quyuzx}/>
            <Route path="/innergl" component={Innergl}/>
            <Route path="/freightinsurance" component={Freightinsurance}/>
            <Route path="/advertising" component={Advertising}/>
            <Route path="/aboutus" component={Aboutus}/>
            <Route path="/logistics" component={Logistics}/>
            <Route path="/insure" component={Insure}/>
            <Route path="/painsure" component={Insure2}/>
           
            {
                homeRoute.map( (route, key)=>{
                    return <Route path={route.pathname} component={route.component} key={key}/>
                })
            }

            <Redirect to='/home' from='/' exact/>
        </Switch>
    </Router>,
    document.getElementById('root')
);

reportWebVitals();



