import React, { Component } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames"; //动态绑定classname插件
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, Space, Button, Tooltip, Input, message } from "antd";
import axios from "axios";

import logo from "../assets/images/home/logo.png";

import bottom from "../assets/images/home/bottom.png";

import "../assets/css/header.css";

const items = [
  {
    key: "3",
    label: <Link to="/innergl">内部业务管理</Link>,
  },
  {
    key: "2",
    label: <Link to="/production">落货统收统配</Link>,
  },

  {
    key: "1",
    label: <Link to="/dedicatedline">区域专线互通</Link>,
  },
];

const items2 = [
  {
    key: "1",
    label: <Link to="/freightinsurance">货运保险</Link>,
  },
  {
    key: "2",
    label: <Link to="/advertising">广告推广</Link>,
  },
];

const user = {
  name: "",
  phone: "",
  gsname: "",
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: {
        current: 1,
      },
      alertStatus: false,
      alertTitle: "提示", //标题
      alertTip: "网络错误", //提示
      cancelText: "取消",
      confirmText: "确认",

      isShortTip: false, //是否为短提示，短提示的情况下不显示'取消''确认'（且2s后消失），且优先级最高，其他配置无效

      isShowCancel: true, //是否显示确认按钮
      isShowConfirm: true, //是否显示确认按钮

      cancelCallbackFn: function () {}, //取消 回调函数
      confirmCallbackFn: function () {}, //确认 回调函数
    };
    console.log(props);
  }
  tabSwitch(index) {
    console.log(index);
    console.log(this.state.tab);
    this.setState({
      tab: {
        ...this.state.tab,
        current: index,
      },
    });
    console.log(this.state.tab);
  }
  udaologin() {
    window.open("https://www.udao56.cn/web/dist/#/login"); //此处的url是全路径
  }
  componentDidMount() {
    console.log(window.location.href.indexOf("logistics"));
    if (window.location.href.indexOf("home") != -1) {
      this.tabSwitch(1);
    }
    if (
      window.location.href.indexOf("dedicatedline") != -1 ||
      window.location.href.indexOf("production") != -1 ||
      window.location.href.indexOf("innergl") != -1
    ) {
      this.tabSwitch(2);
    }
    if (
      window.location.href.indexOf("freightinsurance") != -1 ||
      window.location.href.indexOf("advertising") != -1 ||
      window.location.href.indexOf("painsure") != -1
    ) {
      this.tabSwitch(3);
    }
    if (window.location.href.indexOf("logistics") != -1) {
      this.tabSwitch(4);
    }
    if (window.location.href.indexOf("aboutus") != -1) {
      this.tabSwitch(5);
    }
  }

  componentWillReceiveProps(nextProps) {
    let options = nextProps.dialogOpt || {};

    //如果是短提示
    if (options.isShortTip) {
      options.isShowCancel = false;
      options.isShowConfirm = false;
      setTimeout(() => {
        this.close();
      }, 2000);
    }

    this.setState({
      ...options,
    });
  }

  //取消
  cancel = () => {
    this.state.cancelCallbackFn();
    this.close();
  };
  //确认
  confirm = () => {
    this.state.confirmCallbackFn();
    // this.close();
    this.tjbtn();
  };
  close = () => {
    this.setState({
      alertStatus: false,
    });
  };

  sqsy() {
    this.setState({
      alertStatus: true,
    });
  }

  info = (data) => {
    console.log(data);
    if (data == 1) {
      message.info("申请成功，等待审核！");
    } else {
      message.info(data);
    }
  };

  //申请试用
  tjbtn = () => {
    //获取用户输入的数据（）

    console.log(this.phone.input.value);
    console.log(this.name.input.value);
    console.log(this.gsname.input.value);
    let gsname = this.gsname.input.value;
    let phone = this.phone.input.value;
    let name = this.name.input.value;

    axios
      .post(`https://www.udao56.cn/public/index.php/api/commonapi/add_test`, {
        company: gsname,
        name: name,
        phone: phone,
      })
      .then(
        (response) => {
          console.log(response.data);
          if (response.data.code != 0) {
            this.info(1);
            this.close();
            this.phone.input.value = "";
            this.gsname.input.value = "";
            this.name.input.value = "";
          } else {
            this.info(response.data.msg);
          }
        },
        (error) => {
          console.log(error);
          this.info("接口调用失败，请重新尝试");
        }
      );
  };

  render() {
    const { tab } = this.state;
    let opts = this.state;

    return (
      <>
        {/* 占位 */}
        <div className="toptabzw"></div>
        <div className="toptab">
          <div className="tabcon">
            <div href="/">
              <img src={logo} width="150" height="50" alt="" />{" "}
            </div>
            <div className="tab">
              <div className="tabitem">
                {/* <div className={classNames('item',{'active':tab.current==1})} onClick={() => { this.tabSwitch(1) }} ><Link to="/home/">首页</Link></div> */}
                <div
                  className={tab.current === 1 ? "item active" : "item"}
                  onClick={() => {
                    this.tabSwitch(1);
                  }}
                >
                  <Link to="/home">首页</Link>
                </div>
                <div className={tab.current === 2 ? "item active" : "item"}>
                  <Dropdown
                    menu={{
                      items,
                    }}
                    onClick={() => {
                      this.tabSwitch(2);
                    }}
                  >
                    <a>
                      <Space>
                        平台优势
                        <img
                          className="bottomicon"
                          src={bottom}
                          width="15"
                          height="8"
                          alt=""
                        />
                        {/* <DownOutlined /> */}
                      </Space>
                    </a>
                  </Dropdown>
                </div>

                <div className={tab.current === 3 ? "item active" : "item"}>
                  <Dropdown
                    menu={{
                      items: items2,
                    }}
                    onClick={() => {
                      this.tabSwitch(3);
                    }}
                  >
                    {/* <a onClick={(e) => e.preventDefault()}> */}
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        增值服务
                        <img
                          className="bottomicon"
                          src={bottom}
                          width="15"
                          height="8"
                          alt=""
                        />
                        {/* <DownOutlined /> */}
                      </Space>
                    </a>
                  </Dropdown>
                </div>

                <div
                  className={tab.current === 4 ? "item active" : "item"}
                  onClick={() => {
                    this.tabSwitch(4);
                  }}
                >
                  <Link to="/logistics">物流指数</Link>
                </div>
                <div
                  className={tab.current === 5 ? "item active" : "item"}
                  onClick={() => {
                    this.tabSwitch(5);
                  }}
                >
                  <Link to="/aboutus">关于我们</Link>
                </div>
              </div>
              <div className="tabitem dlbtn">
                <div
                  className="item"
                  onClick={() => {
                    this.udaologin();
                  }}
                >
                  登录
                </div>
                <div
                  className="item itemend"
                  onClick={() => {
                    this.sqsy();
                  }}
                >
                  申请试用
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="syalert"
          style={opts.alertStatus ? { display: "block" } : { display: "none" }}
        >
          <div className="dialog-box">
            <div className="close" onClick={() => this.cancel()}>
              X
            </div>
            <h3>申请试用账号</h3>
            <div>
              {/* <Input ref={user.phone} placeholder="请输入常用手机号" /> */}
              <Tooltip title="必填且为11位纯数字" placement="bottom">
                <Input
                  ref={(c) => (this.phone = c)}
                  placeholder="请输入常用手机号"
                />
              </Tooltip>

              <Tooltip title="必填建议6个字符以内" placement="bottom">
                <Input
                  ref={(c) => (this.name = c)}
                  placeholder="请输入联系人姓名"
                />
              </Tooltip>

              <Tooltip title="必填" placement="bottom">
                <Input
                  ref={(c) => (this.gsname = c)}
                  placeholder="请输入公司名称"
                />
              </Tooltip>

              <div className="btn">
                <button onClick={() => this.tjbtn()}>提交申请</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Header;
