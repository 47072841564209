import React, { Component } from 'react';
import { Button, Tooltip, Space,Input } from 'antd';
import Header from '../../components/header'
import Footer from '../../components/footer'


import item1 from '../../assets/images/zxfw/item2.png'
import item4 from '../../assets/images/zxfw/item4.png'

import '../../assets/css/zzfw.css'




export class Advertising extends Component{

    componentDidMount() {
        window.scrollTo(0, 0);
      }

    render() {
        return (
            <>
                <Header></Header>
                
                <div className='advertising'>
                    <div className='productioncon'>
                        
                    </div>
                </div>
                <div className='advertisingitem'>
                    <div className='itemcon'>
                        <div className='item'>
                            <div className='topimg'>
                                <img src={item4} width="140" height='90' alt="" />
                            </div>
                            <div className='title'>
                                商户广告
                            </div>
                            <div className='con'>
                                精准的行业客户群体，推广范围广阔
                            </div>
                        </div>
                        <div className='item'>
                            <div className='topimg'>
                                <img src={item1} width="140" height='90' alt="" />
                            </div>
                            <div className='title'>
                                物流广告
                            </div>
                            <div className='con'>
                                把物流的品牌，展示到货主眼前
                            </div>
                        </div>
                        
                    </div>
                </div>

                <Footer></Footer>
            </>
        )
       
    }
}
export default Advertising;