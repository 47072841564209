import React, { Component } from "react";
import { Link } from "react-router-dom";
import udaologo from "../assets/images/home/logo2.png";
import "../assets/css/footer.css";

export class Footer extends Component {
  udaologin() {
    window.open("https://www.udao56.cn/web/dist/#/login"); //此处的url是全路径
  }

  render() {
    return (
      <>
        <div className="footer">
          <div className="footercon">
            <div className="leftlogo">
              <img src={udaologo} width="240" height="75" alt="" />
            </div>
            <div className="rightinfo">
              <div className="toptitle">
                <div className="item">
                  <span className="chinese">服务热线</span>
                  <span className="english">/CONTACT</span>
                </div>
                <div className="item">
                  <span className="chinese">关于我们</span>
                  <span className="english">/ABOUT US</span>
                </div>
                <div className="item">
                  <span className="chinese">友情链接</span>
                  <span className="english">/LINKS</span>
                </div>
              </div>
              <div className="con">
                <div className="item">
                  <div>400-670-5156</div>
                </div>
                <div className="item">
                  <div><Link to="/aboutus">公司介绍</Link></div>
                  <div><Link to="/aboutus">联系我们</Link></div>
                </div>
                <div className="item">
                  <div
                    onClick={() => {
                      this.udaologin();
                    }}
                  >
                    优道联运
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerend">
          <div className="footerendcon">© 优道科技 冀ICP备12006906号-2</div>
        </div>
      </>
    );
  }
}
export default Footer;
