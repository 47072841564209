import React, { Component } from "react";
import {
  Map,
  APILoader,
  ScaleControl,
  ToolBarControl,
  Marker,
  ControlBarControl,
} from "@uiw/react-amap";

import Header from "../../components/header";
import Footer from "../../components/footer";

import item1 from "../../assets/images/aboutus/item1.png";
import item2 from "../../assets/images/aboutus/item3.png";
import item3 from "../../assets/images/aboutus/item2.png";
import gsimg from "../../assets/images/aboutus/gsimg.png";
import aboutus from "../../assets/images/aboutus/aboutus.png";
import fzs from "../../assets/images/aboutus/fzs.png";
import logo from "../../assets/images/aboutus/logo.png";

import "../../assets/css/aboutus.css";

//腾讯地图key:3RCBZ-WY66F-XSMJ3-NXBO3-MH7LT-FSBRX
<script src="https://map.qq.com/api/gljs?v=1.exp&key=3RCBZ-WY66F-XSMJ3-NXBO3-MH7LT-FSBRX"></script>;

const Demo = () => (
  <div>
    <Map style={{ height: 350 }} center={[114.53952, 38.03647]}>
      <ScaleControl offset={[16, 30]} position="LB" />
      <ToolBarControl offset={[16, 10]} position="RB" />
      <ControlBarControl offset={[16, 180]} position="RB" />
      <Marker position={[114.53952, 38.03647]}>
        <div className="maptitle">
          <img src={logo} width="80" height="45" alt="" />
          <div>优道联运</div>
        </div>
      </Marker>
    </Map>
  </div>
);

export class Aboutus extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header></Header>

        <div className="aboutus">
          <div className="topimg">
            <img src={aboutus} width="200" height="65" alt="" />
          </div>
          <div className="companyProfile">
            <div className="companyProfilecon">
              <div className="title">公司介绍</div>
              <div className="profilecon">
                <div className="leftcon">
                  优道联运物流面向广大商户及企业提供专业的物流诚信体系建立、评审
                  及监督，规范物流业务流程；提供专线查询、诚信认证、在线下单、运单
                  跟踪等服务，收录了200多家
                  本地运营良好、服务到位、诚信规范的物流
                  企业，运营线路及网点达300多个，物流业务覆盖河北、河南、山东、山
                  西、北京、天津、内蒙、广东、江西、江苏、浙江、陕西、四川等省份
                  <br />
                  <br />
                  平台收集各行业商户近30000家，涉及五金、建材、机械、医药、化工、
                  配件、服装、家具、管件、百货等各个行业。
                  <br />
                  <br />
                  平台借助TMS+商管家+移动端产品，解决从开单到签收结算全流程的
                  运单流转问题，未来重点为广大商户及企业提供放心、有保障、安全、快
                  速的专业物流交易平台。
                </div>
                <div className="rightimg">
                  <img src={gsimg} width="440" height="410" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="lnitem">
            <div className="lnitemcon">
              <div className="item">
                <div className="leftimg">
                  <img src={item1} width="90" height="90" alt="" />
                </div>
                <div className="rightcon">
                  <div className="title">使命</div>
                  <div className="con">
                    减少物流环节，提高物流效率，降低物流成本
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="leftimg">
                  <img src={item2} width="90" height="90" alt="" />
                </div>
                <div className="rightcon">
                  <div className="title">愿景</div>
                  <div className="con">
                    提升网店揽货能力的同时， 减少了运力空载的几率
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="leftimg">
                  <img src={item3} width="90" height="90" alt="" />
                </div>
                <div className="rightcon">
                  <div className="title">理念</div>
                  <div className="con">
                    为专线物流提供更多货源， 也为货物分流提供更多渠道。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="developmenthistory">
            <div className="developmenthistorycon">
              <div className="title">发展历程</div>
              <div className="history">
                <img src={fzs} width="1200" height="400" alt="" />
                <div className="item item1">
                  <div className="title">前身</div>
                  <div>2005年 以定制软件的方式进入物流行业</div>
                </div>
                <div className="item item2">
                  <div className="title">公司发展</div>
                  <div>2005-2010年 在本地零担物流行业 用户量达300家</div>
                </div>
                <div className="item item3">
                  <div className="title">合并转机</div>
                  <div>2011-2013 加入上海拓景 转战车货匹配及物流整合</div>
                </div>
                <div className="item item4">
                  <div className="item item1">
                    <div className="title">重新开始</div>
                    <div>2014-2016 回归本地与物流合作定制开发并成功上线</div>
                  </div>
                </div>
                <div className="item item5">
                  <div className="title">优道科技成立</div>
                  <div>2017年 经过半年的调研，转型为平台运营和管理公司</div>
                </div>
                <div className="item item6">
                  <div className="title">产品上线</div>
                  <div>
                    经过3年的线上与线下实体，测试、整改，第三版优道联运，平台产品上线运营
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="address">
            <div className="addresscon">
              <div className="title">联系我们</div>
              <div className="addressmap" id="addressMap">
                <APILoader
                  version="2.0.5"
                  akay="a7a90e05a37d3f6bf76d4a9032fc9129"
                >
                  <Demo />
                </APILoader>
              </div>
              <div className="info">
                <div className="addr">
                  地址：河北省石家庄市长安区中山路265号吾悦广场东塔
                </div>
                {/* <div className="name">联系人： 李经理</div> */}
                <div className="phone">电话：400-670-5156</div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </>
    );
  }
}
export default Aboutus;
